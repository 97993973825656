import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ProfileInformation from './components/profile_informations/ProfileInformations';
import ProfileSelection from './components/profile_selection/ProfileSelection';
import Header from './components/header/Header';
import { ProfileProvider } from './contexts/ProfileContextType';
import ContentManager from './components/content_manager/ContentManager';
import DownloadApp from './components/apps/DownloadApp';
import Footer from './components/footer/Footer';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login/Login';
import AdminPanel from './components/admin/AdminPanel';
import ProtectedRoute from './components/utils/ProtectedRoute';

import { API_BASE_URL } from './Config';
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

console.log("API Base URL:", API_BASE_URL);
console.log("Debug Mode:", isDebugMode);

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header />
        <Routes>
          <Route path="/login" Component={Login} />
          <Route path="/privacy-policy" Component={PrivacyPolicy} />
          <Route element={<ProtectedRoute allowedRoles={['user','admin']} />}>
            <Route element={<ProtectedRoute allowedRoles={['admin']}/>}>
              <Route path="/admin" Component={AdminPanel} />
            </Route>
            <Route path="/" element={
              <ProfileProvider>
                <ProfileSelection />
                <ProfileInformation />
                <ContentManager />
                <DownloadApp />
              </ProfileProvider>
            } />
          </Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
