// AdminPanel.tsx
import React, { useState, useEffect } from 'react';
import { getUsers, resetPassword } from '../../services/UserService';
import ChangePassword from '../modals/ChangePassword';
import { toast } from 'react-toastify';
import { fetchAllProfiles } from '../../services/ProfileService';
import { ProfileSelectionDTO } from '../../types/ProfileSelectionDTO';

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [profiles, setProfiles] = useState<ProfileSelectionDTO[]>();

  useEffect(() => {
    getUsers().then((data) => {
      setUsers(data);
    }).catch((error) => {
      console.error('Failed to fetch users:', error);
    });
    fetchAllProfiles().then((data) => {
      setProfiles(data);
    }).catch((error) => {
      console.error('Failed to fetch profiles:', error);
    });
  }, []);

  const handleOpenModal = (username: string): void => {
    setSelectedUser(username);
    setShowModal(true);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
    setMessage('');
  };

  const handleResetPassword = (username: string): void => {
    resetPassword(username).then(() => {
      toast.success(`Password reset for user: ${username}`);
    }).catch((error) => {
      toast.error('Failed to reset password');
      console.error('Failed to reset password:', error);
    });
  }

  const handleCopyLink = (username: string) => {
    const loginUrl = `${window.location.origin}/login?username=${username}`;
    navigator.clipboard.writeText(loginUrl)
        .then(() => {
            toast.success("Link copied to clipboard!");
        })
        .catch(err => {
            toast.error("Failed to copy link.");
            console.error('Failed to copy text: ', err);
        });
};

  return (
    <div className="flex flex-col max-w-4xl mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden border border-teal-500">
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-900">Users</h1>
            <ul className="space-y-1 mt-4">
                {users.map((user: string, index: number) => (
                    <li key={index} className="flex font-bold justify-between items-center bg-white p-4 shadow-sm rounded-md border border-gray-300">
                        {user} - {profiles?.find((profile) => profile.name.toLocaleLowerCase() === user[0])?.saved_name}
                        <div className='flex gap-2'>
                        <button 
                              className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              onClick={() => handleCopyLink(user)}
                          >
                              Copy Direct Login Link
                          </button>
                        <button 
                            className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => handleOpenModal(user[0])}>
                            Change Password
                        </button>
                        <button 
                            className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => handleResetPassword(user[0])}>
                            Reset Password
                        </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        {showModal && (
            <ChangePassword 
                selectedUser={selectedUser} 
                isVisible={showModal} 
                onClose={handleCloseModal}
            />
        )}
    </div>
);
};


export default AdminPanel;
